



























































import { api } from "@/api/api";
import { ApiGetCustomerDto, ApiGetEmployeeDto, ApiUpsertCourseParticipantDto, ApiRole } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";
import { formatDate } from "@/shared/helpers/dateHelpers";

export default defineComponent({
  name: "ResourceModal",
  components: { BaseModalForm },
  props: {
    type: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    isVocationalSchool: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const aofEmployee = ref(false);
    const getEmployees = ref([]);
    const employeeNumber = ref();
    const addressBookList = ref<ApiGetCustomerDto[]>([]);
    const aofEmployeeList = ref<ApiGetEmployeeDto[]>([]);
    const resouceRoleList = ref<ApiRole[]>([]);

    const resourceData = ref<ApiUpsertCourseParticipantDto>({
      userId: 0,
      roleName: "",
      status: "påmeldt",
      requiresElectronicInvoice: false,
    });

    const hours = ref<number>(1);

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await loadAddressbookList();
        await loadAofEmployeeList();
        await loadResourceRoleList();
      });
    });

    const postResource = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.course.upsertCourseParticipantAsync(+route.params.id, resourceData.value);
        if (aofEmployee.value) {
          await api.course.insertEmployeeContractLine(+route.params.id, resourceData.value.userId, {
            amountOfHours: hours.value,
          });
        }
        openNotification(store, NotificationItemType.Success, "Ressursen er lagt til kurset");
        context.emit("close");
      });
    };

    const loadAddressbookList = async () => {
      const response = await api.customer.getCustomerPersonsAsync();
      addressBookList.value = response.data.filter((current) => current.isActive);
    };

    const loadAofEmployeeList = async () => {
      const response = await api.user.getEmployeesAsync();
      aofEmployeeList.value = response.data.filter((employee) => employee.isActive);
    };

    const loadResourceRoleList = async () => {
      resouceRoleList.value = (await api.role.getRolesAsync()).data
        .filter((role) => role.isActive && role.isResource && role.isForVocationalSchool === props.isVocationalSchool)
        .sort(localeSortByProperty("name"));
    };

    const onSubmit = async () => {
      const isValid = getValidatableRef(context.refs.form)?.validate();
      if (isValid) {
        postResource();
      }
    };

    return {
      employeeNumber,
      resourceData,
      hours,
      aofEmployee,
      getEmployees,
      resouceRoleList,
      addressBookList,
      aofEmployeeList,
      formatDate,
      onSubmit,
      validateNotEmpty,
      contactsList: computed(() => store.state.contacts.persons),
    };
  },
});
