var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showModal)?_c('BaseModal',[_c('ResourceModal',{attrs:{"headline":_vm.modalHeadline,"type":_vm.modalType,"isVocationalSchool":_vm.isVocationalSchool},on:{"close":_vm.onModalClose}})],1):_vm._e(),_c('v-form',[_c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.resourceList,"search":_vm.search},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_vm._v("Ressurser")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk blant ressurser","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","data-cy":"addResource","btnText":"Legg til ressurs","icon":"mdi-plus"},on:{"handleClick":_vm.newResource}})]},proxy:true},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"seeResource"},on:{"click":function($event){return _vm.viewResource(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Vis ressurs")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"deleteResource"},on:{"click":function($event){return _vm.deleteResource(item.userId)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Slett ressurs")])])]}},{key:"item.userType",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getUserRole(value) === "Customer" ? "Ekstern ressurs" : "AOF-ansatt")+" ")]}},{key:"item.email",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.mobile",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.roleName",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.noTeachingHours",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.noRemainingTeachingHours",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.name",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}}],null,true)},[_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }